<template>
  <div>
    <div class="col-12">
      <p class="larger mb-3"><b>{{ filterItem.name }}</b></p>
      <label>{{ $t('email') }}</label> <info-helper class="ml-1" text="stepEmailDisclaimer" />
      <input type="text"
             :class="['form-text w-100 mb-3', $v.email.$error ? 'form-group--error' : '']"
             @blur="$v.email.$touch"
             v-model="email"
      >
       <div class="form-group">
         <input id="checkbox" class="checkbox float-left mr-2" ref="checkbox" type="checkbox"
                                       v-model="renaming">
         <div class="ml-4 inline-edit-label" for="checkbox">{{ $t('keepOriginalNames') }}</div>
       </div>
      <div class="form-error mb-2" v-if="!$v.email.email">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
      <label>LOD-Level (it only exports the selected level)</label>
      <input class="form-text" type="number" min="0" max="4" v-model="lod" />
        <info-panel class="border-panel compact-header light-header" info-panel-header="SourceCode" :collapsible="true" :initially-collapsed="true" preview-id="something">
          <div slot="info">
            <pre>{{ content }}</pre>
          </div>
        </info-panel>
        <info-panel class="border-panel compact-header light-header" info-panel-header="Preview" :collapsible="true" preview-id="something2">
          <div slot="info">
            <b>{{ $t('Filter') }}</b>
            <div :key="key" v-for="(value, key) in content.filter">
              {{ key }}: {{ value }}
            </div>
            <br >
            {{ $t('axixMultiplier') }}: {{ content.pipeline.axisMultiplier }}<br >
            {{ $t('pivotPlacement') }}: {{ content.pipeline.pivotPlacement }}<br >
            {{ $t('pivotsField') }}: {{ content.pipeline.pivotField }}<br >
            {{ $t('Mode') }}: {{ content.pipeline.subVersion.mode }}
            <div class="w-100 mt-5" />
            <b>{{ $t('Merge') }}</b>
            <div :key="key" v-for="(value, key) in content.merge">
              {{ key }}: {{ value }}
            </div>
            <div v-if="!Object.keys(content.merge)">-</div>
          </div>
        </info-panel>
    </div>
    <vm-selector :show-prices="$store.getters.isSuperAdmin" class="mb-3 col-12 col-md-6" @selected="setVM" />
    <div class="form-error ml-3 col-12 col-md-6" v-if="error">{{ error }}</div>
    <div v-if="!processing" class="col-12">
      <Button @click="$emit('abort')">{{ $t('abort') }} <icon type="times" /></Button>
      <Button @click="triggerVersionProcess" class="icon-rotate-animation make-longer-animation highlight ml-3">{{ $t('start') }} <icon type="cube" /></Button>
    </div>
    <div class="col-12 mt-1" v-if="processing">
      <loading-panel :time-in-seconds="0" message="pleaseWait" />
    </div>
  </div>
</template>

<script>
import LoadingPanel from "@/components/LoadingPanel";
import Button from "@/components/forms/Button";
import Icon from "../components/Icon";
import InfoPanel from "@/components/InfoPanel";
import {email} from "vuelidate/lib/validators";
import InfoHelper from "@/components/InfoHelper";
import vmSelector from "@/components/vmSelector";
export default {
  name: "FilterTriggerForm",
  components: {
    Button,
    LoadingPanel,
    Icon,
    InfoPanel,
    InfoHelper,
    vmSelector
  },
  props:{
    /*
    * @param filterItem {Object} a filter-instance, needs to contain the asset as an include
    * */
    filterItem: {type: Object, required: true},
    projectId: {type: String, required: true},
    rootNode: {type: String, required: true}
  },
  data() {
    return {
      //axisMultiplier: [0,0,0],
      assetContent: {},
      content: {},
      mode: 'exclude',
      error: '',
      processing: false,
      email: this.$store.getters.getUserEmail(),
      renaming: false,
      vm: '',
      lod: '0'
    };
  },
  validations: {
    email: {
      email,
    },
  },
  beforeMount() {
    this.assetContent = this.filterItem.content ? JSON.parse(this.filterItem.content) : {};
    if(this.assetContent) {
      this.content.pipeline = this.assetContent.pipeline; // we copy the contents to the instance because in case the asset is changed we will know what the reference was
      this.content.filter = this.assetContent.filter;
      this.content.finalizedFilter = this.assetContent.finalizedFilter;
      this.content.merge = this.assetContent.merge;
      this.content.finalizedMerge = this.assetContent.finalizedMerge;
    }
  },
  methods: {
    setVM(id) {
      console.log(this.vm);
      this.vm = id;
    },
    async triggerVersionProcess() {
      this.processing = true;

        if(this.rootNode) {
          const fileUri = `${this.rootNode}/lod${this.lod}.fbx`;
          if(!this.content.pipeline.subVersion) {
            this.content.pipeline.subVersion = {};
          }
          this.content.pipeline.subVersion.rootNodeId = `${this.rootNode}`;
          this.content.pipeline.rootNodeFile = `lod${this.lod}.fbx`;
          this.content.pipeline.files = [fileUri];
          this.content.pipeline.sendFilterMails = true;
          this.content.pipeline.email = this.content.pipeline.email = this.email ? this.email : this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId);
          this.content.pipeline.lang = this.$i18n.locale ? this.$i18n.locale : 'en';
          if(this.renaming) {if(!this.content.pipeline.subVersion) {this.content.pipeline.subVersion = {}} this.content.pipeline.subVersion.renaming = true}
        }
        else {
          this.error = this.$t('errors.rootNodeNotFound');
        }
      this.$store.dispatch('createFilterInstance', {
          id: this.projectId,
          name: this.filterItem.name + '_export',
          args: [{
            assetId: this.filterItem.id,
            content: JSON.stringify(this.content),
          }]
        }).then(async instance => {
        if(instance) {
          await this.$store.dispatch('clientInstanceToPipeline', {
            id: instance[0].id,
            type: 'filter',
            virtualMachine: this.vm,
          }).then(() => {
            this.processing = false;
            this.$emit('started');
          }).catch(e => {
            this.error = e.response.errors.message;
            this.processing = false;
          });
        }
      });
    },
  }
}
</script>

<style scoped>
pre {
  color: #fff;
  word-break: break-all;
}

</style>